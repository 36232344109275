// @flow

import React, { Component } from "react";
import SocialProofTooltip from "../components/SocialProofTooltip";
import styled from "styled-components";
import apiMethod from "../api/apiMethod";
import update from "immutability-helper";

type tooltip = {
  id: number,
  type: number,
  plan: "crypto" | "premium" | "premium_plus" | "premium_lifetime" | "premium_plus_lifetime",
  country: string,
  time: string,
  destroyTimeout: number,
  count:number,
  areLinksActive:boolean
}

type SocialProofTooltipContainerState = {
  tooltip: tooltip
};

const maxRuns = 10;
function removeType(type) {
  return (t) => t != type;
}

class SocialProofTooltips extends Component<null, SocialProofTooltipContainerState> {
  xhr: any;
  shown: number = 0;

  constructor(props) {
    super(props);

    this.state = {
      typesToReceive: this.props.types,
      tooltips: []
    }
  }

  getSocialProof() {
    const params = { types: this.state.typesToReceive.join(",") };
    this.xhr = apiMethod("getservicenewsfeed", params, ret => {
      
      let typesToReceive = this.state.typesToReceive;
      if (ret.type == 3 || ret.type == 4) {
        typesToReceive = typesToReceive.slice().filter(removeType(ret.type));
      }

      const tooltip: tooltip = {
        id: this.shown,
        message: ret.type,
        plan: ret.plan,
        country: ret.country,
        time: ret.timeago,
        views: ret.count,
        ...this.props
      }

      this.props.onShow(tooltip);
      const tooltips = update(this.state.tooltips, { $push: [tooltip] });
      this.setState({ tooltips: tooltips, typesToReceive: typesToReceive });

      this.shown++;
      if (this.shown < 10 && typesToReceive.length) {
        this.getSocialProof();
      } else {
        this.xhr = null;
      }
    }, { timeout: 0, forceFresh: true });
  }

  

  componentDidMount() {
    this.getSocialProof();
  }

  componentWillUnmount() {
    if (this.xhr) {
      this.xhr.abort();
    }
  }

  render() {
    const tooltips = this.state.tooltips.map(tooltip => <SocialProofTooltip key={tooltip.id} {...tooltip} />);
    return <Container>{tooltips}</Container>
  }
}
SocialProofTooltips.defaultProps = {
  onClick: () => {},
  onShow: () => {}
};

const Container = styled.div`
  padding: 10px;
  position:fixed;
  bottom:0px;
  overflow:hidden;
  display: inline-block;
  vertical-align: bottom;
`

export default SocialProofTooltips;
