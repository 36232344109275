import React from "react";
import { Provider } from "react-redux";

import apiConfig, { EU_API, US_API } from "../api/config";
import apiMethod from "../api/apiMethod";
import apiInit from "../api/apiInit";

import Popup from "../lib/Popup";
import authModal from "../lib/authModal";
import componentMount from "../lib/componentMount";
import LoginOrSignup from "../containers/UserAuthentication2/LoginOrSignup";
import Signup from "../containers/UserAuthentication/Signup";
import Signin from "../containers/UserAuthentication/Signin";
import Notifications from "../components/Notifications";
import UserDropdown from "../components/UserDropdown";
import UserHeader from "../components/UserHeader";
import SocialProofTooltips from "../containers/SocialProofTooltips";
import CookieConsent from "../components/CookieConsent";
import CookieConsentModal from "../components/CookieConsentModal";
import VivacomRegistration from "../containers/VivacomRegistration";
import UnverifiedUsersAlert from "../components/UnverifiedUsersAlert";
import PromoModal from "../components/PromoModal";
import RegistrationForm from "../containers/UserAuthentication/InvitationRegistration/RegistrationForm";
import Timer from "../components/Timer";
import SupportRatingAgent from "../components/SupportRatingAgent";

import store from "../lib/state/store";
import actionCreators from "../lib/state/actions";
import { userState, notificationsState, quotaState, sharesState, businessState } from "../lib/state";
import { initTracking, GA_FLAG, FBQ_FLAG } from "../lib/tracking";

import { initAnonymousNotifications, pushSubscribe, initOutisdeNotificationsPopup } from "../lib/notifications/pushSubscribe";

import { getSearch, getHash, rcookie, setcookie, isSameLocation, getLocationById } from "../lib/utils";

import { EU_LOCATIONID, US_LOCATIONID } from "@pcloud/web-utilities/dist/config/constants";

const state = {
  dispatch: (...args) => store.dispatch(...args),
  getState: (...args) => store.getState(...args),
  subscribe: (...args) => store.subscribe(...args),
  actions: bindActionCreators(actionCreators, store.dispatch)
};

const components = {
  LoginOrSignup: componentMount(props => <Provider store={store}><LoginOrSignup {...props} /></Provider>),
  Signin: componentMount(props => <Provider store={store}><Signin {...props} /></Provider>),
  Signup: componentMount(props => <Provider store={store}><Signup {...props} /></Provider>),
  UserHeader: componentMount(props => <Provider store={store}><UserHeader {...props} /></Provider>),
  SocialProofTooltips: componentMount(props => <SocialProofTooltips {...props} />),
  CookieConsent: componentMount(props => <CookieConsent {...props} />),
  CookieConsentModal: componentMount(props => <CookieConsentModal {...props} />),
  VivacomRegistration: componentMount(props => <Provider store={store}><VivacomRegistration {...props} /></Provider>),
  UnverifiedUsersAlert: componentMount(props => <Provider store={store}><UnverifiedUsersAlert {...props} /></Provider>),
  PromoModal: componentMount(props => <PromoModal {...props} />),
  RegistrationForm: componentMount(props => <Provider store={store}><RegistrationForm {...props} /></Provider>),
  Timer: componentMount(props => <Timer {...props} />),
  SupportRatingAgent: componentMount(props => <SupportRatingAgent {...props} />),
};

const notifications = {
  init: initAnonymousNotifications,
  ask: pushSubscribe,
  initOutisdeNotificationsPopup: initOutisdeNotificationsPopup
};

self.pCloudGlobals = {
  apiInit,
  apiConfig,
  apiMethod,
  Popup,
  authModal,
  components,
  getSearch,
  isSameLocation,
  getHash,
  rcookie,
  setcookie,
  state,
  notifications,
  initTracking,
  GA_FLAG,
  FBQ_FLAG,
  EU_LOCATIONID,
  US_LOCATIONID,
  EU_API,
  US_API,
  getLocationById
  
};

function bindActionCreators(actionCreators, dispatch) {
  let bindActions = {};
  for (let reducer in actionCreators) {
    if (!(reducer in bindActions)) {
      bindActions[reducer] = {};
    }

    for (let action in actionCreators[reducer]) {
      bindActions[reducer][action] = (...args) =>
        dispatch(actionCreators[reducer][action](...args));
    }
  }

  return bindActions;
}
