import React from "react";
import styled, { keyframes } from "styled-components";
import reactStringReplace from "react-string-replace";
import { hiDPI } from "polished";
import Componentify from "react-componentify";

import { __ } from "../lib/translate";
import { convertTime } from "../lib/utils";
import { URLSITE } from "@pcloud/web-utilities/dist/config";
import { divClassTagConverter, linkConverter, strongConverter } from "../lib/componentifyConverters";

const countryTranslations = {
  "Australia": __("social_proof_country_au", "Australia"),
  "United States": __("social_proof_country_us", "United States"),
  "Canada": __("social_proof_country_ca", "Canada"),
  "United Kingdom": __("social_proof_country_uk", "United Kingdom"),
  "New Zealand": __("social_proof_country_nz", "New Zealand")
};

class SocialProofTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      timeCycle: {
        timePaused: null,
        timeStarted: null,
        timeHovered: null,
        remainingTime: this.props.destroyTimeout,
      },
    };
    this._onHover = this._onHover.bind(this);
    this._onMouseLeave = this._onMouseLeave.bind(this);
    this.renderMessage = this.renderMessage.bind(this);
  }

  _initTimer() {
    this.timeout = setTimeout(() => {
      this.setState({ visible: false });
    }, this.state.timeCycle.remainingTime);
  }

  _onHover() {
    clearTimeout(this.timeout);
    this.setState((prevState) => ({
      timeCycle: {
        ...prevState.timeCycle,
        timePaused: new Date(),
      },
    }));
  }

  _onMouseLeave() {
    var pastTime =
      this.state.timeCycle.timePaused - this.state.timeCycle.timeStarted;
    this.setState((prevState) => ({
      timeCycle: {
        ...prevState.timeCycle,
        remainingTime: this.props.destroyTimeout - pastTime,
      },
    }));
    this._initTimer();
  }

  renderMessage() {
    const { plan, message, views, areLinksActive, country } = this.props;

    if (message === 1) {
      let text = <Componentify
        text={__(
          "social_proof_type1",
          "<div class='social-tooltip-header'>User from <strong>%country%</strong></div> <div class='social-tooltip-body'>recently signed up for https://www.pcloud.com[pCloud]</div>",
          {
            "country": countryTranslations[country]
          }
        )}
        converters={[divClassTagConverter, strongConverter, linkConverter]}
      />

      return text;
    }

    if (message === 2) {
      let text = <Componentify
        text={__(
          "social_proof_type2",
          "<div class='social-tooltip-header'>User from <strong>%country%</strong></div> <div class='social-tooltip-body'>recently bought %url%[%plan%]</div>",
          {
            "country": countryTranslations[country],
            "url": planTypes[plan].link,
            "plan": planTypes[plan].title
          }
        )}
        converters={[divClassTagConverter, strongConverter, linkConverter]}
      />
  
      return text;
    }

    if (message === 3) {
      let text = <Componentify
        text = {__(
          "social_proof_type3",
          "<strong>%number%</strong> people are viewing this site",
          {
            number: views
          }
        )}
        converters={[strongConverter]}
      />
  
      return (
        <MessageViews>
          {text}
        </MessageViews>
      )
    }

    if (message === 4) {
      let text = <Componentify
        text = {__(
          "social_proof_type4",
          "<strong>%number%</strong> people signed up in the last 24 hours",
          {
            number: views
          }
        )}
        converters={[strongConverter]}
      />
  
      return (
        <MessageViews>
          {text}
        </MessageViews>
      )
    }

    if (message === 5) {
      let text = <Componentify
        text={__(
          "social_proof_type5",
          "<div class='social-tooltip-header'>User from <strong>%country%</strong></div> <div class='social-tooltip-body'>recently moved from Dropbox to https://www.pcloud.com[pCloud]</div>",
          {
            "country": countryTranslations[country]
          }
        )}
        converters={[divClassTagConverter, strongConverter, linkConverter]}
      />

      return text;
    }

    if (message === 6) {
      let text = <Componentify
        text={__(
          "social_proof_type6",
          "<div class='social-tooltip-header'>User from <strong>%country%</strong></div> <div class='social-tooltip-body'> recently backed up his Google Photos account to https://www.pcloud.com[pCloud]</div>",
          {
            "country": countryTranslations[country]
          }
        )}
        converters={[divClassTagConverter, strongConverter, linkConverter]}
      />

      return text;
    }
  };

  componentDidMount() {
    this.setState((prevState) => ({
      timeCycle: {
        ...prevState.timeCycle,
        timeStarted: new Date(),
      },
    }));
    this._initTimer();
  }

  render() {
    return this.state.visible ? (
      <TooltipWrapper
        onMouseEnter={this._onHover}
        onMouseLeave={this._onMouseLeave}
      >
        {this.props.plan ? planTypes[this.props.plan].icon : <MovedIcon />}
        <TooltipMessage areLinksActive={this.props.areLinksActive}>
          {this.renderMessage()}
          <TooltipTime>
            <span>{convertTime(this.props.time)}</span>
          </TooltipTime>
        </TooltipMessage>
      </TooltipWrapper>
    ) : null;
  }
}

SocialProofTooltip.defaultProps = {
  areLinksActive: true,
  destroyTimeout: 5000,
  onClick: () => {},
};

export default SocialProofTooltip;

const jumpin = keyframes`
  0% {
    opacity: 0;
    bottom:-30px;
    transform: scale(0.92);
  }

  100% {
    opacity: 1;
    bottom:0px;
    transform: scale(1);
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  margin: 10px;
  width: 290px;
  min-height: 70px;
  border-radius: 35px;
  background-color: #fff;
  letter-spacing: 0.4px;
  box-shadow: 0px 1px 12px -6px rgba(0, 0, 0, 0.28);
  animation-name: ${jumpin};
  animation-duration: 400ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .social-tooltip-header {
    font-size: 0.75em;
    color: #444;
  }

  .social-tooltip-body {
    font-size: 0.68em;
    color: #999;
    margin: 3px 0px;
  }
`;

const TooltipMessage = styled.div`
  padding: 10px 12px 12px 0px;
  & a {
    color: #5cbdec;
    text-decoration: none;
    cursor: ${(props) => (props.areLinksActive ? "pointer" : "default")};
  }
`;

const TooltipText = styled.div`
  font-size: 0.68em;
  color: #999;
  margin: 3px 0px;
`;

const MessageViews = styled(TooltipText)`
  line-height: 45px;
  & strong {
    color: #444;
  }
`;

const TooltipTime = styled.div`
  font-size: 0.65em;
  color: #999;
`;

const TooltipIcon = styled.div`
  width: 44px;
  height: 44px;
  margin: 13px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
`;

const CryptoIcon = styled(TooltipIcon)`
  background-image: url(${require("../../root/img/socialproof/crypto.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/socialproof/crypto@2x.png")});
  }
`;

const PremiumIcon = styled(TooltipIcon)`
  background-image: url(${require("../../root/img/socialproof/premium500.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/socialproof/premium500@2x.png")});
  }
`;

const PremiumPlusIcon = styled(TooltipIcon)`
  background-image: url(${require("../../root/img/socialproof/premium2tb.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/socialproof/premium2tb@2x.png")});
  }
`;

const MovedIcon = styled(TooltipIcon)`
  background-image: url(${require("../../root/img/socialproof/moved.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/socialproof/moved@2x.png")});
  }
`;

const planTypes = {
  crypto: {
    icon: <CryptoIcon />,
    link: "https://www.pcloud.com/encrypted-cloud-storage.html",
    title: __("social_proof_encryption", "Encryption"),
  },
  premium: {
    icon: <PremiumIcon />,
    link: "https://www.pcloud.com/cloud-storage-pricing-plans.html",
    title: __("social_proof_premium", "Premium 500GB"),
  },
  premium_plus: {
    icon: <PremiumPlusIcon />,
    link: "https://www.pcloud.com/cloud-storage-pricing-plans.html",
    title: __("social_proof_premium_plus", "Premium+ 2TB"),
  },
  premium_lifetime: {
    icon: <PremiumIcon />,
    link: "https://www.pcloud.com/cloud-storage-pricing-plans.html",
    title: __("social_proof_lifetime_premium", "Lifetime 500GB"),
  },
  premium_plus_lifetime: {
    icon: <PremiumPlusIcon />,
    link: "https://www.pcloud.com/cloud-storage-pricing-plans.html",
    title: __("social_proof_lifetime_premium_plus", "Lifetime 2TB"),
  },
  premium_custom_10tb: {
    icon: <PremiumPlusIcon />,
    link: "https://www.pcloud.com/cloud-storage-pricing-plans.html",
    title: __("social_proof_lifetime_custom", "Lifetime 10TB"),
  }
};
