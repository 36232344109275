// @flow
import React, { PureComponent } from "react";
import styled, { keyframes } from "styled-components";

import { __ } from "../lib/translate";

type Props = {
  timeExpire: string | number,
  timeExpireCallback: () => void,
  theme: "dark" | "light" | "purpleOrange"
};

type State = {
  seconds: string,
  minutes: string,
  hours: string
};

const THEMES = {
  dark: {
    backgroundColor: "#353535",
    textColor: "#ffffff"
  },
  light: {
    backgroundColor: "#fafafa",
    textColor: "#444444",
    border: "1px solid #eaeaea"
  },
  purpleOrange: {
    backgroundImage: "linear-gradient(to bottom, #642b73, #e46952)",
    textColor: "#ffffff"
  },
  default: {
    backgroundColor: "#353535",
    textColor: "#ffffff"
  }
};

class Timer extends PureComponent<Props, State> {
  static defaultProps = {
    timeExpireCallback: () => {}
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      seconds: "00",
      minutes: "00",
      hours: "00"
    };

    (this: any).countDown = (this: any).countDown.bind(this);
  }

  componentDidMount() {
    const { timeExpire, timeExpireCallback } = this.props;
    const timeExp = new Date(timeExpire).getTime();
    let timeNow = new Date().getTime();
    let timeBetween = timeExp - timeNow;

    const timer = setInterval(() => {
      if (timeNow >= timeExp) {
        timeExpireCallback();
        clearInterval(timer);
      } else {
        timeBetween = timeExp - timeNow;
        this.countDown(timeBetween);
        timeNow = new Date().getTime();
      }
    }, 1000);
  }

  countDown(timeBetween: number) {
    const hours = Math.floor(timeBetween / (1000 * 60 * 60));
    const minutes = Math.floor((timeBetween % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeBetween % (1000 * 60)) / 1000);

    this.setState({
      seconds: this.formatTimeXX(seconds),
      minutes: this.formatTimeXX(minutes),
      hours: this.formatTimeXX(hours)
    });
  }

  formatTimeXX(time: number) {
    const timeStr = String(time);
    return timeStr.length === 1 ? "0" + timeStr : timeStr;
  }

  render() {
    const { seconds, minutes, hours } = this.state;
    const { timeExpire, theme } = this.props;

    const timeExp = new Date(timeExpire).getTime();
    const timeNow = new Date().getTime();
    const themeStyle = THEMES[theme] ? theme : "default";

    const hoursLenght = String(hours).length;
    // const hoursCardsArr = Array.from(Array(hoursLenght), (_, i) => (
    //   <Card key={i} themeStyle={themeStyle} />
    // ));

    const hoursCardsArr = [];
    for (var i = 0; i < hoursLenght; i++) {
      hoursCardsArr.push(<Card key={i} themeStyle={themeStyle} />);
    }

    // if (timeNow >= timeExp) {
    //   return null;
    // }

    return (
      <CounterCont>
        <span>
          <ValueWrapper>
            <Value themeStyle={themeStyle}>{hours}</Value>
            {hoursCardsArr}
          </ValueWrapper>
          <Label>{__("hours")}</Label>
        </span>
        <i>:</i>
        <span>
          <ValueWrapper>
            <Value themeStyle={themeStyle}>{minutes}</Value>
            <Card themeStyle={themeStyle} />
            <Card themeStyle={themeStyle} />
          </ValueWrapper>
          <Label>{__("minutes")}</Label>
        </span>
        <i>:</i>
        <span>
          <ValueWrapper>
            <Value themeStyle={themeStyle}>{seconds}</Value>
            <Card themeStyle={themeStyle} />
            <Card themeStyle={themeStyle} />
          </ValueWrapper>
          <Label>{__("seconds")}</Label>
        </span>
      </CounterCont>
    );
  }
}

const CounterCont = styled.div`
  display: flex;
  justify-content: center;

  i {
    line-height: 47px;
    margin: 0px 10px;
    font-family: "Roboto", sans-serif;
    font-size: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
    color: #d9d9d9;

    @media (max-width: 350px) {
      margin: 0px 6px;
    }
  }
`;
const ValueWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;
const Value = styled.span`
  position: absolute;
  color: #fff;
  font-size: 26px;
  letter-spacing: 24px;
  left: 12px;
  top: 10px;
  z-index: 1;

  font-family: "Roboto", sans-serif;
  font-size: 26px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  color: ${props =>
    THEMES[props.themeStyle]["textColor"]
      ? THEMES[props.themeStyle]["textColor"]
      : "#ffffff"};
`;
const Card = styled.div`
  display: inline-block;
  width: 35px;
  height: 47px;
  margin: 0px 2px;
  border-radius: 4.4px;
  box-shadow: 0px 1px 0 0 rgba(0, 0, 0, 0.07);
  border: ${props =>
    THEMES[props.themeStyle]["border"]
      ? THEMES[props.themeStyle]["border"]
      : "none"};
  background-color: ${props =>
    THEMES[props.themeStyle]["backgroundColor"]
      ? THEMES[props.themeStyle]["backgroundColor"]
      : "#353535"};
  background-image: ${props =>
    THEMES[props.themeStyle]["backgroundImage"]
      ? THEMES[props.themeStyle]["backgroundImage"]
      : "none"};
  box-sizing: border-box;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    margin-top: -0.5px;
    background-color: ${props =>
      THEMES[props.themeStyle]["backgroundColor"]
        ? THEMES[props.themeStyle]["backgroundColor"]
        : "#353535"};
    background-image: ${props =>
      THEMES[props.themeStyle]["backgroundImage"]
        ? THEMES[props.themeStyle]["backgroundImage"]
        : "none"};
    z-index: 2;
  }
`;
const Label = styled.div`
  margin-top: 6px;
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  color: #999999;
  text-transform: uppercase;
`;
export default Timer;
