// @flow

import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { hiDPI } from "polished";
import $ from "jquery";

import apiMethod from "../api/apiMethod";

import { __ } from "../lib/translate";
import { debounce } from "../lib/utils";

import InputStyledCheckbox from "./InputStyledCheckbox";
import InputStyledRadio from "./InputStyledRadio";

const MAX_TEXTAREA_LENGTH = 1000;

type Answer = {
  id: number,
  title: string,
  type: "text" | "radio" | "checkbox",
  nextquestionid: number
};

type Question = {
  id: number,
  iconId: number,
  header: string,
  subheader: string,
  title: string,
  answers: Array<Answer>,
  nextquestionid: number | null,
  buttons: Array<string>
};

type Params = {
  token: string,
  vote: number,
  answerid?: number,
  answerids?: string
};

type Props = {
  token: string,
  vote: number,
  questions: Array<Question>,
  startFromQuestionId: number | null
};

const SupportRatingAgent = ({ token, vote, questions, startFromQuestionId }: Props) => {
  const [currentQuestion, setCurrentQuestion] = useState(
    questions.find(question => question.id === startFromQuestionId)
  );
  const [charLeftTexarea, setCharLeftTexarea] = useState(MAX_TEXTAREA_LENGTH);
  const [nextIdQst, setNextIdQst] = useState();
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const inputTextarea = useRef(null);

  (charLeftTexarea: number);
  (nextIdQst: number | null);
  (selectedAnswers: Array<Answer>);

  useEffect(() => {
    apiMethod("survey/vote", { token, vote }, res => {}, {
      forceFresh: true,
      cacheTime: 0,
      errorCallback: ({ error }) => {
        throw new Error(error);
      }
    });
  }, [token, vote]);

  useEffect(() => {
    const { nextQuestionId: nextquestionid } = currentQuestion;
    setNextIdQst(currentQuestion.nextquestionid);
  }, [currentQuestion]);

  const onAnswer = (params: any, onSuccsess: () => void) => {
    console.log("onAnswer>>> params", params);
    apiMethod(
      "survey/answer",
      params,
      res => {
        onSuccsess();
      },
      {
        forceFresh: true,
        cacheTime: 0,
        errorCallback: ({ error }) => {
          throw new Error(error);
        }
      }
    );
  };

  const onInputSubmit = (selectedAnswers: Array<Answer>) => {
    const params = getParams(selectedAnswers);

    onAnswer(params, () => {});
  };

  const getParams = (selectedAnswers: Array<Answer>) => {
    const params: Params = { token, vote };

    if (selectedAnswers.length === 0) {
      return params;
    }

    params.answerids = selectedAnswers.map(answer => answer.id).join(",");

    selectedAnswers.forEach(answer => {
      if (answer.type === "textarea") {
        params[answer.id] = answer.text;
      }
    });

    return params;
  };

  const onInputChange = (answer: Answer, e: any) => {
    const selectedAnswer = Object.assign({}, answer);
    const { nextquestionid: nextIdQstFromAnsw, type, id } = selectedAnswer;
    const isChecked = e.target.checked;
    let updatedAnswers = [...selectedAnswers];

    if (selectedAnswers.length !== 0) {
      if (type === "radio") {
        updatedAnswers = selectedAnswers.filter(item => item.type !== "radio");
        updatedAnswers.push(selectedAnswer);
      } else if (type === "checkbox") {
        if (isChecked) {
          updatedAnswers.push(selectedAnswer);
        } else {
          const indexOfAnswer = selectedAnswers.findIndex(item => item.id === id);
          updatedAnswers.splice(indexOfAnswer, 1);
        }
      }
    } else {
      updatedAnswers = [...selectedAnswers, selectedAnswer];
    }

    if (nextIdQstFromAnsw) {
      setNextIdQst(nextIdQstFromAnsw);
    }
    console.log("onInputChange>>>>updatedAnswers", updatedAnswers);
    onInputSubmit(updatedAnswers);
    setSelectedAnswers(updatedAnswers);
  };

  const onChangeText = (answer: Answer) => {
    let updatedAnswers = [...selectedAnswers];
    const textareaValue = inputTextarea.current.value;

    if (textareaValue !== "") {
      const selectedAnswer = {
        ...answer,
        text: textareaValue
      };
      updatedAnswers = selectedAnswers.filter(item => item.type !== "textarea");
      updatedAnswers.push(selectedAnswer);
    } else {
      updatedAnswers = selectedAnswers.filter(item => item.type !== "textarea");
    }
    console.log("onChangeText>>>>updatedAnswers", updatedAnswers);
    onInputSubmit(updatedAnswers);
    setSelectedAnswers(updatedAnswers);
  };

  const onTextareaKeyUp = (e: any) => {
    const textareaValue = inputTextarea.current.value;

    if (textareaValue >= MAX_TEXTAREA_LENGTH) {
      return;
    }

    if (textareaValue !== "") {
      setCharLeftTexarea(MAX_TEXTAREA_LENGTH - textareaValue.length);
    } else {
      setCharLeftTexarea(MAX_TEXTAREA_LENGTH);
    }
  };

  const renderAnswer = (answer: Answer, index: number) => {
    const { id, title, type, nextquestionid: nextIdQstFromAnsw } = answer;
    const isChecked = Boolean(selectedAnswers.find(item => item.id === id));

    if (type === "textarea") {
      return (
        <React.Fragment key={id}>
          {title ? <TextAreaTitle>{title}</TextAreaTitle> : null}
          <AnswerTextArea
            ref={inputTextarea}
            onChange={debounce(() => onChangeText(answer), 500)}
            onKeyUp={onTextareaKeyUp}
            maxLength="1000"
          />
          <MessageLeftChar>
            {__("general_branding_headline_limit").replace("%characters_left%", charLeftTexarea)}
          </MessageLeftChar>
        </React.Fragment>
      );
    } else if (type === "radio") {
      return (
        <RowInput key={id}>
          <InputStyledRadio
            text={title}
            id={`radio${index}`}
            name="radio-group"
            checked={isChecked}
            onChange={(e: any) => {
              onInputChange(answer, e);
            }}
          />
        </RowInput>
      );
    } else if (type === "checkbox") {
      return (
        <RowInput key={id}>
          <InputStyledCheckbox
            text={title}
            id={`checkbox${index}`}
            name="checkbox-group"
            checked={isChecked}
            onChange={(e: any) => {
              onInputChange(answer, e);
            }}
          />
        </RowInput>
      );
    }
  };

  const renderQuestion = () => {
    const { title, answers = [] } = currentQuestion;

    if (answers.length === 0) {
      return null;
    }

    return (
      <Row>
        <QeustionTitle>{title}</QeustionTitle>
        <AnswersWrapper>{answers.map(renderAnswer)}</AnswersWrapper>
      </Row>
    );
  };

  const onButtonClick = () => {
    const nextQuestion = questions.find(question => question.id === nextIdQst);
    $(window).scrollTop(0);
    setCurrentQuestion(nextQuestion);
  };

  const renderButton = (buttonName: string, index: string) => {
    if (buttonName === "submit") {
      return (
        <Button key={index} onClick={onButtonClick}>
          {__("Submit")}
        </Button>
      );
    } else if (buttonName === "next") {
      return (
        <Button key={index} onClick={onButtonClick}>
          {__("Next")}
        </Button>
      );
    } else if (buttonName === "skip") {
      return (
        <Button key={index} className="skip" onClick={onButtonClick}>
          {__("Skip")}
        </Button>
      );
    }
  };

  const { iconId, header, subheader, buttons = [] } = currentQuestion;
  const Icon = supportIcons[iconId];
  return (
    <Wrapper>
      <Form>
        <Row>
          <Icon />
        </Row>
        <Row>
          <Header>{header}</Header>
        </Row>
        <Row>
          <Subheader>{subheader}</Subheader>
        </Row>
        <Row>{renderQuestion()}</Row>
        <BtnWrapper>{buttons.map(renderButton)}</BtnWrapper>
      </Form>
    </Wrapper>
  );
};

export default SupportRatingAgent;

const Wrapper = styled.div`
  background-color: #f8f8f8;
  padding: 120px 0 60px;

  @media only screen and (max-width: 640px) {
    padding: 50px 0 0 0;
  }
`;

const Form = styled.div`
  display: flex;
  margin: 0 auto;
  padding: 48px;
  width: 640px;
  height: auto;
  box-shadow: 0px 2px 8px 0 rgba(0, 0, 0, 0.03), 0 0 1px 0 rgba(0, 0, 0, 0.01);
  background-color: #ffffff;
  align-self: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;

  @media only screen and (max-width: 640px) {
    width: 100%;
    padding: 48px 15px;
    background-color: #fafafa;
  }
`;

const Row = styled.div`
  width: auto;
  font-family: "Roboto", sans-serif;
  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

const IconThanks = styled.div`
  display: block;
  width: 82px;
  height: 82px;
  margin: 0 auto;
  margin-bottom: 42px;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${require("../../root/img/rate-images/thanks.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/rate-images/thanks@2x.png")});
  }
`;

const IconSent = styled(IconThanks)`
  background-image: url(${require("../../root/img/rate-images/sent.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/rate-images/sent@2x.png")});
  }
`;

const IconRest = styled(IconThanks)`
  background-image: url(${require("../../root/img/rate-images/rest.png")});
  ${hiDPI(1.5)} {
    background-image: url(${require("../../root/img/rate-images/rest@2x.png")});
  }
`;

const Header = styled.div`
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 7px;

  @media only screen and (max-width: 640px) {
    font-size: 24px;
  }
`;

const Subheader = styled.div`
  text-align: center;
  font-size: 19px;
  color: #888888;

  @media only screen and (max-width: 640px) {
    font-size: 16px;
  }
`;

const QeustionTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  color: #212121;
  margin-top: 22px;

  &:first-child {
    margin-top: 72px;
  }

  @media only screen and (max-width: 640px) {
    font-size: 16px;
  }
`;

const TextAreaTitle = styled(QeustionTitle)``;

const AnswerTextArea = styled.textarea`
  width: 544px;
  height: 100px;
  box-shadow: inset 0px 2px 3px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #c1c1c1;
  background-color: #fafafa;
  resize: none;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 9px;

  @media only screen and (max-width: 640px) {
    width: 100%;
    background-color: #fff;
  }
`;

const MessageLeftChar = styled.span`
  font-size: 14px;
  font-style: italic;
  text-align: right;
  margin-top: 9px;
  color: #888888;
`;

const AnswersWrapper = styled.div`
  display: flex;
  width: 544px;
  align-self: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;

  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

const RowInput = styled.div`
  display: flex;
  width: 100%;
  height: 18px;
  margin-bottom: 22px;
  align-items: center;
  &:first-child {
    margin-top: 30px;
  }

  @media only screen and (max-width: 640px) {
    margin-bottom: 25px;
    &:first-child {
      margin-top: 28px;
    }
  }
`;

const Input = styled.input`
  height: 18px;
  cursor: pointer;
`;

const Label = styled.label`
  margin-left: 12px;
  height: 18px;
  cursor: pointer;
`;

const Button = styled.div`
  width: 200px;
  height: 44px;
  border-radius: 4.4px;
  text-align: center;
  color: #ffffff;
  background-color: #17bed0;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  margin: 0 4px;
  /* margin-bottom: 48px; */
  cursor: pointer;

  &.skip {
    background-color: #b9b9b9;
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

const BtnWrapper = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 55px;

  @media only screen and (max-width: 640px) {
    width: 100%;
  }
`;

const supportIcons = [IconThanks, IconSent, IconRest];
