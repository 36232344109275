//@flow

export type ColorType =
  | "cyan"
  | "lightCyan"
  | "red"
  | "redCaution"
  | "lightCaution"
  | "redReset"
  | "black"
  | "green"
  | "darkGreen"
  | "gray"
  | "lightgray1"
  | "lightgray2"
  | "orange"
  | "transparent"
  | "halloweenOrange";

export type PlanIDType =
  | 0
  | 1
  | 3
  | 4
  | 7
  | 8
  | 12
  | 13
  | 14
  | 15
  | 17
  | "17_1_A"
  | "17_4_A"
  | 101
  | 111
  | 130
  | 131;

export type PeriodType = "month" | "year" | "lifetime";

export type PriceObject = {
  [PlanIDType]: {
    price: number,
    originalPrice: number
  }
}

export type DisabledPlan = {
  [PlanIDType]: {
    disabledReason: boolean
  }
}

export type PricingPageSteps = "choose-plan" | "signin" | "purchase";
export type PricingPageURLProduct = "premium" | "premium-plus" | "add-500GB" | "add-2TB" | "family1" | "family2" | "crypto" | "extended-history";
export type CurrencyType = "$" | "€" | "₤";

export const TFA_TYPE = {
  MSISDN_TYPE: 1,
  AUTHENTICATOR_APP_TYPE: 2
};

export type Contact = {
  name: string,
  source: number,
  value: string
}

export type UserContacts = Array<Contact>;

export type JourneySteps = {
  verifymail: boolean,
  uploadfile: boolean,
  downloaddrive: boolean,
  downloadapp: boolean,
  autoupload: boolean,
  sentinvitation: boolean
};

export type BasicUserinfo = {
  business: false,
  countryCode: string,
  cryptosetup: boolean,
  cryptov2isactive: boolean,
  email: string,
  emailverified: boolean,
  freequota: number,
  haspassword: boolean,
  journey: {
    steps: JourneySteps
  },
  language: Language,
  lastsubscription?: Subscription,
  msisdn: string,
  password: string,
  plan: PlanIDType,
  premium: boolean,
  premiumlifetime: boolean,
  quota: number,
  registered: string,
  subscriptions: Array<Subscription>,
  usedquota: number,
  userCountry: string,
  userid: number,
  verifiedPhoneNumber: string,
  vivapcloud: boolean
};

export type BusinessUserInfo = BasicUserinfo & { business: true, account: AccountInfo };

export type Userinfo = BasicUserinfo | BusinessUserInfo;

export type ActiveTfaType = typeof TFA_TYPE.MSISDN_TYPE | typeof TFA_TYPE.AUTHENTICATOR_APP_TYPE;

export type AccountInfo = {
  active: boolean,
  assigned: boolean,
  can_modify: boolean,
  can_modify_settings: boolean,
  can_share: boolean,
  cryptosetup: boolean,
  cryptov2isactive: boolean,
  email: string,
  expires: string,
  firstname: string,
  frozen: boolean,
  id: number,
  invalidate_password: boolean,
  lastactivity: string,
  lastname: string,
  ldap: boolean,
  owner: boolean,
  position: string,
  is_trial: boolean
};

export type UserSettings = {
  security: {
    activeTfaType: ActiveTfaType,
    secretKey: string
  }
};

export type Subscription = {
  status: "active" | "canceled",
  istrial: boolean,
  planid: number,
  iscancelable: boolean,
  isbillable: boolean,
  billingprovider: number,
  period: "month" | "year",
  products: Array<Number>
};

export type DiscountType = {
  isused: boolean,
  discountcode: {
    hastrial: boolean,
    isbundle: boolean,
    lifetimediscount: number,
    isdurationforever: boolean,
    type: "percent" | "fixed",
    products: Array<PlanIDType>,
    periods: Array<"month" | "year" | "lifetime">
  }
}
